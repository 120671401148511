import {useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {ROLES} from '../accessRights/AccessRightsConstants'
import {getAllStatesWithOfficesAction} from '../redux/actions/SettingActions'
import _ from 'lodash'
import {
    getActiveRoleSelector,
    getUserDataSelector,
    isUserSuperAdminSelector,
} from '../redux/selectors/AuthSelectors'
import {geyAllStatesWithOfficesSelector} from '../redux/selectors/SettingsSelectors'
import {getUserProfileAction} from '../redux/actions/AuthActions'
import useCustomAccessRights from '../accessRights/useCustomAccessRights'

const useOfficeLocation = (
    defaultLocation = null,
    needAllLocationKey = true,
    showAllOfficeforcefully = false
) => {
    const dispatch = useDispatch()
    const [selectedOfficeLocation, setSelectedOfficeLocation] = useState(null)
    const activeRole = useSelector(getActiveRoleSelector)
    const userData = useSelector(getUserDataSelector)
    const allStatesWithOffices = useSelector(geyAllStatesWithOfficesSelector)
    const {officeAccess} = useCustomAccessRights()

    useEffect(() => {
        dispatch(getAllStatesWithOfficesAction())
        if (activeRole == ROLES.standard.roleName) dispatch(getUserProfileAction())
    }, [])

    const officeList = useMemo(() => {
        let officeData = []
        if (showAllOfficeforcefully || officeAccess == 'all_offices') {
            let oData = []
            allStatesWithOffices.map((item) => {
                if (item?.office?.length > 0) {
                    const offData = item?.office?.map((oItem) => ({
                        name: oItem?.office_name,
                        value: oItem?.id,
                    }))
                    oData = [...oData, ...offData]
                }
            })
            officeData = [...officeData, ...oData]
        } else {
            if (userData?.office?.id && userData?.office?.office_name) {
                officeData.push({name: userData?.office?.office_name, value: userData?.office?.id})
            }

            if (userData?.additional_location?.length > 0) {
                userData?.additional_location.map((item) => {
                    if (item?.office_name && item?.office_id) {
                        officeData.push({name: item?.office_name, value: item?.office_id})
                    }
                })
            }
        }

        let finalOfficeData = _.sortBy(officeData, 'name')
        if ((needAllLocationKey && officeAccess == 'all_offices') || showAllOfficeforcefully) {
            finalOfficeData = [{name: 'All Offices', value: 'all'}, ...finalOfficeData]
        }

        return finalOfficeData
    }, [
        showAllOfficeforcefully,
        officeAccess,
        needAllLocationKey,
        allStatesWithOffices,
        userData?.office?.id,
        userData?.office?.office_name,
        userData?.additional_location,
    ])

    useEffect(() => {
        if (officeList?.length > 0) {
            if (officeList?.some((item) => item?.value == defaultLocation)) {
                setSelectedOfficeLocation(defaultLocation)
            } else {
                let officeItem = officeList?.find((item) => item?.value == userData?.office_id)
                if (
                    (needAllLocationKey && officeAccess == 'all_offices') ||
                    showAllOfficeforcefully
                ) {
                    setSelectedOfficeLocation('all')
                } else {
                    setSelectedOfficeLocation(officeItem?.value ?? officeList?.[0]?.value)
                }
            }
        }
    }, [defaultLocation, officeList])

    return [officeList, selectedOfficeLocation, setSelectedOfficeLocation]
}

export default useOfficeLocation
